.download{
    margin-bottom: 5vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.download button{
    width: fit-content;
    margin: 1vw auto;
    padding: 1vw;
    border: none;
    border-radius: 5px;
    background-color: #025F56;
    color: white;
    cursor: pointer;
}
