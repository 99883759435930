.carousel {
    position: relative;
    /* height: 100vh; */
    /* border-radius: 2.5%; */
    overflow: hidden;
    user-select: none;
    font-size: 1rem;
    height:80vh;
}

.leftslide {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 4rem;
    left: 0;
    padding: 0 1rem;
    z-index: 1;
    user-select: none;
}

.leftslide span {
    display: block;
}

.rightslide {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 4rem;
    right: 0;
    padding: 0 1rem;
    z-index: 1;
    user-select: none;
}

.rightslide span {
    display: block;
}

.carousel-controls {
    color: white;
}

.leftslide, .rightslide{
    opacity: 0.5;
    cursor: pointer;
}

.leftslide:hover, .rightslide:hover {
    opacity: 1;
}

.carousel-dots {
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 50%;
    height: 1.5rem;
    transform: translateX(-50%);
    padding: 1rem 0;
    z-index: 1;
}

.carousel-dots button {
    height:100%;
    width: 1.5rem;
    border-radius: 50%;
    background-color: white;
    margin: 0 4px;
    transition: opacity 2s ease-in-out;
}

.carousel-dot.active {
    cursor: pointer;
    opacity: 1;
}

.carousel-dot:not(.active) {
    cursor: pointer;
    opacity: 0.5;
}

@media screen and (max-width:800px) {
    .carousel {
        height:50vh;
    }
}

@media screen and (max-width:550px) {
    .carousel {
        height:40vh;
    }
}

