.about-outer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-outer img{
    width: 100%;
    height: 70vh;
}

.about{
    width: 75vw;
    border-radius: 25px;
    background-color: rgb(244, 244, 244);
    position: relative;
    top: -25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vw;
}

.about h1{
    color: #025F56;
    font-family: serif;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    column-gap: 1vw;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.about h1:hover{
    cursor: pointer;
}

.about .about-us{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    width: 100%;
}

.about .leadership{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    width: 100%;
}

.about .financial{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    width: 100%;
}

.about .sponsors{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    width: 100%;
}

.about .location{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    width: 100%;
}

.about .line{
    display: block;
    width: 50vw;
    height: .3vw;
    background: #F17211;
    margin: 2vw auto;
    margin-left: 0px;
}

@media only screen and (max-width:700px) {
    .about{
        width: 85vw;
        border-radius: 25px;
        background-color: rgb(244, 244, 244);
        position: relative;
        top: -20vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2vw;
    }
    .about-outer img{
        width: 100%;
        height: 60vh;
    }
    .about .line{
        display: block;
        width: 70vw;
        height: .6vw;
        background: #F17211;
        margin: 2vw auto;
        margin-left: 0px;
    }
    
}

@media only screen and (max-width:450px) {
    .about{
        width: 85vw;
        border-radius: 25px;
        background-color: rgb(244, 244, 244);
        position: relative;
        top: -10vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2vw;
    }
    .about-outer img{
        width: 100%;
        height: 40vh;
    }
    .about .line{
        display: block;
        width: 70vw;
        height: .6vw;
        background: #F17211;
        margin: 2vw auto;
        margin-left: 0px;
    }
    
    
}