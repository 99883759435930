@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
  .spinner-container {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
    z-index: 2000;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top:0px;
    left: 0px;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.417);
  }