.editor{
    border-style: solid;
    border-width: 2px;
    border-color: #000000;
    padding: 1vw;
    margin: 0px;
}

.editor label{
    margin: 0px;
    padding: 0px;
}
.editor .editorClassName button{
    width:3vw;
    height: 3vw;
    padding: 0px;
    margin: 0px;
}
.editor span{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0px;
}

