.footer{
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: #025f56;
    color: #ffffff;
    overflow: hidden;
}

/* Social media links section */
.footer-socialmedialinks{
    display: flex;
    justify-content: right;
    align-items: center;
    height: 1.5vw;
    padding: 0.5vw 0.5vw;
    background-color: rgb(244, 244, 244);
}

.social-links a{ 
    font-size: 1.5vw;
    margin: 0 1vw;
}

/* Footer body section*/
.footer-body{
    display: grid;
    grid-template-columns: 30vw 58vw;
     /* 10vw 10vw 30vw; */
    column-gap: 4vw;
    margin: 1vw;
    justify-content: center;
}

.footer-body .footer-body-inner {
    display: grid;
    grid-template-columns: 10vw 10vw 30vw;
    column-gap: 4vw;
    justify-content: center;
}

.footer-body h3{
    text-align: center;
    margin: 1vw 0px;
}

.about-us{
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
}

.about-us img{
    width: 8vw;
}
.about-us p{
    text-align: justify;
}
.quick-links {
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-body .connect{
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.footer-body a{
    text-decoration: none;
    color: white;
    margin: 0px;
}

.footer-body a:hover{
    cursor: pointer;
}

.footer-body p{
    margin: 0.7vw 0.5vw;
    width: 100%;
}

.footer-body .contact{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1vw;
    font-weight: 500;
}

.footer-body .iframe-map{
    height: 40vh;
    width: 100%;
}



/* CopyRight section */
.footer-copyright{
    height: 2vw;
    padding: 0.5vw 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
    color: white;
    background-color: #01403a;
}

/* Media queries for responsive design */

@media screen and (max-width: 1024px){
    .footer-body{
        display: grid;
        grid-template-columns: 35vw 55vw;
        column-gap: 1vw;
        margin: 1vw;
        justify-content: center;
    }

    .footer-body .footer-body-inner {
        display: grid;
        grid-template-columns: 15vw 10vw 30vw;
        column-gap: 1vw;
        justify-content: center;
    }

    .footer-copyright{
        height: 2vw;
        padding: 1vw 0;        
        font-size: 1.5vw;
    }

    .footer-socialmedialinks{
        height: 2.5vw;
        padding: 0.5vw 0.5vw;
    }
    
    .social-links a{ 
        font-size: 2.5vw;
        margin: 0 1.5vw;
    }
}

@media screen and (max-width: 750px){
    .footer-body{
        display: grid;
        grid-template-columns: 80vw;
        /* 15vw 10vw 30vw; */
        column-gap: 1vw;
        margin: 1vw;
        justify-content: center;
    }

    .footer-body .footer-body-inner {
        display: grid;
        grid-template-columns: 15vw 15vw 50vw;
        column-gap: 3vw;
        justify-content: center;
    }
    .footer-copyright{
        height: 3vw;
        padding: 1vw 0;        
        font-size: 2vw;
    }
    .footer-socialmedialinks{
        height: 3vw;
        padding: 0.5vw 0.5vw;
    }
    
    .social-links a{ 
        font-size: 3vw;
        margin: 0 2vw;
    }

    .footer-body h3{
        font-size: 2.2vw;
    }
    .footer-body p{
        font-size: 2vw;
    }
    .footer-body .contact a{
        font-size: 2vw;
    }
    .footer-body .iframe-map{
        height: 20vh;
        width: 100%;
    }
}
@media screen and (max-width: 500px){
    .footer-body{
        display: grid;
        grid-template-columns: 80vw;
        /* 15vw 15vw 30vw; */
        column-gap: 1vw;
        margin: 1vw;
        justify-content: center;
    }
    .footer-body .footer-body-inner {
        display: grid;
        grid-template-columns: 20vw 20vw 50vw;
        column-gap: 1vw;
        justify-content: center;
    }
    .footer-copyright{
        height: 3vw;
        padding: 1vw 0;        
        font-size: 2vw;
    }
    .footer-socialmedialinks{
        height: 3vw;
        padding: 0.5vw 0.5vw;
    }
    
    .social-links a{ 
        font-size: 3vw;
        margin: 0 2vw;
    }

    .footer-body h3{
        font-size: 2.5vw;
    }
    .footer-body p{
        font-size: 2.5vw;
    }
    .footer-body .contact a{
        font-size: 2vw;
    }
    .footer-body .iframe-map{
        height: 20vh;
        width: 100%;
    }
}
/* Social media icons */
.youtube-social {
    color: #FF0000;
}

.facebook-social {
    color: #4267B2
}

.twitter-social {
    color: #1DA1F2
}
