.partners{
    margin: 2vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.partners p{
    margin: 0px;
    font-size: 1.2vw;
    text-align: justify;
}
.partners h3{
    margin-top: 0px;
    margin-bottom: 1.5vw;
}
.partners h2{
    margin-left: 7.5vw;
}
.partners .right{
    width: 80vw;
    height: fit-content;
    margin-left:3vw;
    animation: linear alternate;
    animation-name: rightrun;
    animation-duration: 3s;
    min-height: 10vw;
}

@keyframes rightrun {
    0% {
      top: 0%;
      transform:  translateX(100%);
    }
    100% {
      top: 100%;
      transform:  translateX(0%);
    }
}
.partners .left{
   width: 10vw;
   height: 10vw;
   animation:ease-in; 
   animation-name: leftrun;
   animation-duration: 3s;
  /* transition: height 8s ease-in-out; */
}

@keyframes leftrun {
    0% {
      height: 0px;
      width: 0px;
    }
    100% {
      height: 10vw;
      width: 10vw;
    }
}

 @media screen and (max-width:800px) {
    .partners .left{
        height: 15vw;
        width: 15vw;
        margin: auto 0px;
    }
    .partners .right{
        height: fit-content;
        width: 75vw;
        min-height: 15vw;
    }
    .partners p{
        font-size: 2vw;
    }
    @keyframes leftrun {
        0% {
          height: 0px;
          width: 0px;
        }
        100% {
          height: 15vw;
          width: 15vw;
        }
    }
}

@media screen and (max-width:450px) {
    .partners .left{
        height: 20vw;
        width: 20vw;
        margin: auto 0px;
    }
    .partners .right{
        height: fit-content;
        width: 68vw;
        min-height: 20vw;
    }
    .partners p{
        font-size: 3vw;
    }
    @keyframes leftrun {
        0% {
          height: 0px;
          width: 0px;
        }
        100% {
          height: 20vw;
          width: 20vw;
        }
    }
}
/*
@media screen and (max-width:450px) {
    .partners-deck {
        display: grid;
        grid-template-columns: 40vw 40vw ;
        grid-gap: 3vw;
        justify-content: center;
        margin: 3vw auto;
        padding-bottom: 5vh;
    }
    .partners .card {
        position: relative;
        border-radius: 2%;
        overflow: hidden;
        color: white;
        cursor: pointer;
        height: 40vw;
    }
    .partners .card-description {
        font-size: 2vw;
    }
    .partners .mini-caption {
        position: relative;
        height:18%;
        width: 90%;
        background: #025f56;
        color: white;
        font-weight: 400;
        font-size: 2vw;
        display:flex;
        align-items: center;
        justify-content:center;
        overflow: hidden;
        line-height: 120%;
        padding: 0px 5%;
    
    }
} */