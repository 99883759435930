.homepage-carousel {
    position: absolute;
    height:100%; 
    width: 100%; 
    z-index: 0;
}

.homepage-carousel-slides {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
}

.homepage-carousel-slide-item {
   position: absolute;
   height: 100%;
   width: 100%; 
   overflow: hidden;
   transition: opacity 2s ease-in-out;
}

.homepage-carousel-slide-item.active {
    z-index:2;
    opacity: 1;
}

.homepage-carousel-slide-item:not(.active) {
    z-index: 1;
    opacity : 0;
}

.homepage-carousel-slide-item img {
    height: 100%;
    width: 100%;
}
