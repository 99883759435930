.welcome{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #025f56;
    color: #F17211;
}
.welcome h1{
    font-family: californian-fb-text, serif;
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 3vw;
}
.welcome h1:after{
    content: "";
    display: block;
    width: 10vw;
    height: .2vw;
    background: #F17211;
    margin: 2vw auto;
    margin-bottom: 0px;
}

.welcome .story{
    display: block;
    width: 50%;
    text-align: center;
    font-size: 1.3vw;
    color: white;
    padding: 2vw;
}

.welcome button{
    border-radius: 5px;
    border-color: white;
    border-width: 1px;
    font-weight: 700;
    font-size: 1.3vw;
    color: white;
    background-color:#00000000;
    padding: 1vw;
    margin: 2vw auto;
    cursor: pointer;
}


@media screen and (max-width:800px) {
    .welcome h1{
        font-size: 3.5vw;
    }
    .welcome h1:after{
        width: 15vw;
    }
    .welcome .story{
        width: 70%;
        font-size: 2vw;
    }  
    .welcome button{
        border-width: 1.5px;
        font-size: 2vw;
        padding: 1.5vw;
    }
}

@media screen and (max-width:500px) {
    .welcome h1{
        font-size: 4vw;
    }
    .welcome h1:after{
        width: 15vw;
    }
    .welcome .story{
        width: 70%;
        font-size: 2.5vw;
    }  
    .welcome button{
        border-width: 1.5px;
        font-size: 2.5vw;
        padding: 2vw;
    }
}

