.card {
    position: relative;
    border-radius: 2%;
    overflow: hidden;
    color: white;
    cursor: pointer;
    height: 15vw;
}

.card:hover .card-description{
    height: 100%;
    background-color: #025f56cb;
}

.card-image {
    height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.card-image img {
    height: 82%;
    width: 100%;
    display: block;
}

.mini-caption {
    /* position: relative; */
    height:18%;
    width: 90%;
    background: #025f56;
    color: white;
    font-weight: 400;
    font-size: 1vw;
    display:flex;
    align-items: center;
    justify-content:center;
    overflow: hidden;
    line-height: 75%;
    padding: 0px 5%;

}

.card-description {
    overflow: hidden;
    position: absolute;
    height: 0;
    top: 0;
    z-index: 2;
    transition:all 1s ease-in-out;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    font-size: 1vw;
}

.card-description div {
    word-wrap: break-word;
    margin: 1vw;
    overflow: hidden;
}

@media screen and (max-width:800px) {
    .card {
        height: 20vw;
    }
    .mini-caption {
        /* position: relative; */
        height:18%;
        width: 90%;
        background: #025f56;
        color: white;
        font-weight: 400;
        font-size: 1.5vw;
        display:flex;
        align-items: center;
        justify-content:center;
        overflow: hidden;
        line-height: 75%;
        padding: 0px 5%;
    }
    .card-description {
        overflow: hidden;
        position: absolute;
        height: 0;
        top: 0;
        z-index: 2;
        transition:all 1s ease-in-out;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items:flex-start;
        font-size: 1.5vw;
    }
}

@media screen and (max-width:450px) {
    .card{
        height: 25vw;
    }
    .mini-caption {
        /* position: relative; */
        height:18%;
        width: 90%;
        background: #025f56;
        color: white;
        font-weight: 400;
        font-size: 2vw;
        display:flex;
        align-items: center;
        justify-content:center;
        overflow: hidden;
        line-height: 75%;
        padding: 0px 5%;

    }
    .card-description {
        overflow: hidden;
        position: absolute;
        height: 0;
        top: 0;
        z-index: 2;
        transition:all 1s ease-in-out;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items:flex-start;
        font-size: 2.5vw;
    }
}

