.donateCard {
    border: 2px #00a090 solid;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: hidden;
    cursor: pointer;
    background-color:azure;
    font-size: 1.1rem;
    width:30vw;
    margin:0px;
    height:70vh;
}

.donateCard:hover {
    border: 3px #00a090 solid;
    box-shadow: none;
    transition: all linear 0.5s;
}

.donateCardHeader {
    width: 100%;
    overflow: hidden;
}

.donateCardHeader img{
    display: block;
    height:225px;
    width:100%;
    overflow: hidden;
}

.donateCardBody {
    margin:0.5rem 1.5rem;
}

.donateHeading {
    padding: 0.3rem 0;
}

.createdBy {
    padding: 0.3rem 0;
}

.progressDetails {
    padding: 0.3rem 0;
    display: flex;
    justify-content:flex-start;
    align-items: center;
}

.donateStats p {
    margin: 0;
    padding: 0.3rem 0;
    font-size: 1.1rem;
    position:relative;
    display: flex;
    justify-content: space-between;
}

.donateCardFooter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 1rem;
    padding-top: 0.3rem;
    cursor: pointer;
}

.shareBtn {
    height: 2.5rem;
    width:6rem;
    background:none;
    border-radius: 8px;
    border: 2px #F17211 solid;
    cursor:pointer;
    font-size: 1.1rem;
}

.shareBtn:hover {
    background-color:#F17211;
    font-weight: bold;
    transition: all linear 0.5s;
}

.donateBtn {
    height: 2.5rem;
    width: 6rem;
    border-radius: 8px;
    border: 2px #F17211 solid;
    background:none;
    font-size:1.1rem;
    cursor: pointer;
}

.donateBtn:hover {
    background-color:#F17211;
    font-weight: bold;
    transition: all linear 0.5s;
}

@media only screen and (max-width:1000px) {
    .donateCard {
        width:45vw;
        height:70vh;
    }
    
}

@media only screen and (max-width:700px) {
    .donateCard {
        width:55vw;
        height:70vh;
    }
}
@media only screen and (max-width:450px) {
    .donateCard {
        width:75vw;
        height:70vh;
    }
    .shareBtn {
        height: 2.5rem;
        width:30vw;
        background:none;
        border-radius: 8px;
        border: 2px #F17211 solid;
        cursor:pointer;
        font-size: 1.1rem;
    }
    .donateBtn {
        height: 2.5rem;
        width: 30vw;
        border-radius: 8px;
        border: 2px #F17211 solid;
        background:none;
        font-size:1.1rem;
        cursor: pointer;
    }
}
